export { default as Layer1Icon } from '@assets/icons/products/layer1-icon.svg';
export { default as Layer2Icon } from '@assets/icons/products/layer2-icon.svg';
export { default as Layer3Icon } from '@assets/icons/products/layer3-icon.svg';
export { default as DatasetsIcon } from '@assets/icons/products/datasets-icon.svg';
export { default as Bookmark } from '@assets/icons/products/bookmark.svg';
export { default as ReviewProductIcon } from '@assets/icons/products/review-product-icon.svg';
export { default as DraftProductIcon } from '@assets/icons/products/draft-product-icon.svg';
export { default as RejectedProductIcon } from '@assets/icons/products/rejected-product-icon.svg';
export { default as ApprovedProductIcon } from '@assets/icons/products/approved-product-icon.svg';
export { default as CloseSidebarIcon } from '@assets/icons/products/close-sidebar-icon.svg';
