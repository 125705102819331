export { default as ForumIcon } from '@assets/icons/forum/forum-icon.svg';
export { default as AdminForumIcon } from '@assets/icons/forum/admin-forum-icon.svg';
export { default as HeartIcon } from '@assets/icons/forum/heart.svg';
export { default as ChatBubble } from '@assets/icons/forum/chat-bubble.svg';
export { default as FlagIcon } from '@assets/icons/forum/flag-icon.svg';
export { default as BellIcon } from '@assets/icons/forum/bell-icon.svg';
export { default as UserTalkingIcon } from '@assets/icons/forum/user-talking-icon.svg';
export { default as ForumWrenchIcon } from '@assets/icons/forum/forum-wrench-icon.svg';
export { default as ForumUserIcon } from '@assets/icons/forum/forum-user-icon.svg';
export { default as WiredGearIcon } from '@assets/icons/forum/wired-gear-icon.svg';
export { default as ImageIcon } from '@assets/icons/forum/image-icon.svg';
export { default as ThreadIcon } from '@assets/icons/forum/thread-icon.svg';
export { default as ForumCommentBubble } from '@assets/icons/forum.svg';
